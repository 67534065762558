import dynamic from 'next/dynamic'
import { observerService } from '@/utils/observer'
import { useClickOutsideListenerRef } from '@/utils/useClickOutsideListener'
import { pushDataLayerEventHandler } from '@/utils/utils'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { getParamsFromQuery } from 'services/queryString'
import css from './PrimaryNav.module.scss'
import { MASTERPLAN_LANDING_ENDPOINT } from '@/utils/masterplanConstant'
import Image from 'next/image'
import { liveGifar, liveGifen } from '@/utils/constant'
const IconoGraphy = dynamic(()=>import('../IconoGraphy')) 
const SupportDropdown = dynamic(()=>import('./SupportDropdown')) 

let ClientMethod = null
let importMethods = null
const PrimaryNav = (props) => {
  const {
    locale,
    navbarOpen,
    handleNavbar,
    isOpenAppHeader,
    metaData,
    slugQueries = {},
    pageType,
    headerCities = [],
    statusTag,
    stickyHeader
  } = props
  const [activeMenu, setActiveMenu] = useState(null)
  const [findToggle, setFindToggle] = useState(false)
  const [autionToggle, setAutionToggle] = useState(false)
  const [forSale, setForSale] = useState(false)
  const [forRent, setForRent] = useState(false)
  const [forInternational, setForInternational] = useState(false)
  const [headerCitiesNavbar, setHeaderCitiesNavbar] = useState([])
  const [showSupportDropdown, setshowSupportDropdown] = useState(false)
  const [MegamenuDesktop, setMegamenuDesktop] = useState(null)
  const [propertyCat, setPropertyCat] = useState(null)
  const isDesktop = useMediaQuery({ minWidth: 992 })
  const router = useRouter()
  const { query } = router
  const { t, lang } = useTranslation('translations')
  const routerParams = getParamsFromQuery({ ...query, ...slugQueries })
  const textColor = css.navbar__lightheader
  const mobileMenuRef = useRef()

  const handlePopupAuctions = () => {
    setAutionToggle(false)
  }
  const handleMegaMenu = () => {
    setActiveMenu('')
    setForSale(false)
    setForRent(false)
    setForInternational(false)
    setFindToggle(false)
  }
  const refAuctionPopup = useClickOutsideListenerRef(handlePopupAuctions)
  const refMegaMenu = useClickOutsideListenerRef(handleMegaMenu)
  const liveLabel = lang === 'en' ? liveGifen : liveGifar

  useEffect(() => {
    const find_dropdown_onclose_pages = ['SRP List', 'SRP Map']
    if(isDesktop && findToggle && find_dropdown_onclose_pages.includes(pageType)) {
      setFindToggle(false)
    }
  }, [stickyHeader])

  const commercialData = headerCities?.commercialData || headerCitiesNavbar?.commercialData

  // close mobile side menu on outside click
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        navbarOpen &&
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(e.target) &&
        e.target.id !== 'crossIcon'
      )
        handleNavbar(true)
    }
    document.addEventListener('click', checkIfClickedOutside)
    return () => document.removeEventListener('click', checkIfClickedOutside)
  }, [navbarOpen])

  // to get countries list @sourabh_C
  useEffect(() => {
    async function fetchMenuHeader() {
      if (!headerCities) {
        const [menuHeaderRes] = await Promise.all([
          fetch(`${process.env.NEXT_PUBLIC_CMS_STRAPI_BASE_URL}/header-footer?locale=${locale === 'ar' ? 'ar-SA' : 'en'}`),
        ])
        const [menuHeader] = await Promise.all([menuHeaderRes.json()])
        setHeaderCitiesNavbar(menuHeader?.navigationLinks || {})
      }
    }
    fetchMenuHeader()
  }, [])

  useEffect(() => {
    if (!navbarOpen) {
      setActiveMenu('')
      document.body.style.overflow = 'auto'
    } else {
      if (!isDesktop) document.body.style.overflow = 'hidden'
    }
  }, [navbarOpen])
  /**
   * added to handle event of favourite, seen, contacted property
   */
  useEffect(() => {
    const observer = observerService.observable().subscribe((val) => {
      if (val?.data?.type == 'headerToTop' && val?.data?.data) {
        if (val?.data?.data?.top && navbarOpen) handleNavbar(navbarOpen)
      }
    })
    return () => observer.unsubscribe
  }, [navbarOpen])

  const dynamicImportMethods = async () => {
    if(!importMethods) {
      importMethods = await import(/*webpackChunkName: "ImportMethods" */ './Methods/importMethods')
    }
  }

  const loadDynamicContent = async () => {
    await dynamicImportMethods()
    if(headerCitiesNavbar && !MegamenuDesktop) {importMethods.importMegamenuDesktop({ setMegamenuDesktop })}
  }

  useEffect(() => {
    loadDynamicContent();
  },[headerCitiesNavbar])

  const importClientSideMethods = async () => {
    if(!ClientMethod) {
      ClientMethod = await import(/*webpackChunkName : "ClientSideMethod"*/'./Methods/PrimaryNavClientMethod')
    }
  }

 
  // manage countries list @sourabh_C
  const getMegamenuData = async(temp, forType, propertyCategory) => {
    await importClientSideMethods()
    ClientMethod.getMegamenuData({temp, forType, setForSale, setForRent, setForInternational, setHeaderCitiesNavbar, forSale, forRent, headerCities, forInternational, propertyCategory, t, propertyCat, setPropertyCat, commercialData})
  }

  // common action to manage NavAction @sourabh_C
  const commonNavAction = async(tempFlag) => {
    await importClientSideMethods()
    ClientMethod.commonNavAction({tempFlag, setActiveMenu, setFindToggle, setAutionToggle, setshowSupportDropdown, findToggle, autionToggle, showSupportDropdown})
  }

  useEffect(() => {
    if (typeof window === 'undefined') return <></>
    if (headerCities) if (typeof window === 'undefined') return <></>
  }, [])

  const dataLayerHandler = async(eventName, pageSrc, dataDetails = {}) => {
    await importClientSideMethods()
    ClientMethod.dataLayerHandler(eventName, pageSrc, routerParams, lang, pushDataLayerEventHandler, dataDetails)
  }

  /**
   * To send EventHandler for click on matchmaker from Hamburger menu
   * @param eventData - banner type and banner position
   */

  let navTitle = ''
  if (forSale) {
    navTitle = t('common.FOR_SALE')
  } else if (forRent) {
    navTitle = t('common.FOR_RENT')
  } else {
    navTitle = t('common.INTERNATIONAL')
  }


  return (
    <nav className={css.navbar}>
      <div
        className={`${css.navbar__content} ${!navbarOpen ? css.isNotMobile : ''} ${
          (isOpenAppHeader !==null) && (!isOpenAppHeader ? css.navbar_openappContainerHeight : '')
        }`}
      >
        <div className={css.desktopview}>
          <div className={css.has_dropdown} ref={refMegaMenu}>
            <div
              id = "find"
              className={`${css.navbar__link} ${textColor} ${activeMenu === 'find' ? css.active : ''}`}
              onClick={() => {
                commonNavAction(0)
                dataLayerHandler('find_clicked', 'Header')
              }}
            >
              {t('common.FIND')}
            </div>
            <div className={`${css.dropdown}  ${findToggle ? css.headerNav : ''} sheadernav`}>
              <div
                className={`sdropdowncontent ${css.dropdown_content} ${css.dropdown_finddrop} ${
                  forSale || forInternational || forRent ? css.megamenuopen : ''
                }`}
              >
                <div className={css.dropdown_innerrow}>
                  <div className={css.dropdown_find}>
                  <div className={css.dropdown_leftsec}>
                    <h3>{t('auctionLite.RESIDENTIAL')}</h3>
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        getMegamenuData(0, t('common.FOR_SALE'), t('auctionLite.RESIDENTIAL'))
                        dataLayerHandler('for_sale', 'Header')
                      }}
                    >
                      <div className={css.navbar__dropdown__dropdownitem} id = "forSaleResidential">
                        <span className={css.navbar__dropdown__dropdownitem_icon}>
                          <IconoGraphy iconClass={'icon-sale'} iconColor={'color_purple'}></IconoGraphy>
                        </span>
                        <span className={css.navbar__dropdown__dropdownitem__text}>
                          {t('common.FOR_BUY_LABEL_SIDEBAR')}
                          <p> {t('common.FOR_SALE_SUBTITLE')}</p>
                        </span>
                      </div>
                    </a>
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        getMegamenuData(1, t('common.FOR_RENT'), t('auctionLite.RESIDENTIAL'))
                        dataLayerHandler('for_rent', 'Header')
                      }}
                    >
                      <div className={css.navbar__dropdown__dropdownitem} id = "forRentResidential">
                        <span className={css.navbar__dropdown__dropdownitem_icon}>
                          <IconoGraphy iconClass={'icon-rent'} iconColor={'color_purple'}></IconoGraphy>
                        </span>
                        <span className={css.navbar__dropdown__dropdownitem__text}>
                          {t('common.FOR_RENT_LABEL_SIDEBAR')}
                          <p> {t('common.FOR_RENT_SUBTITLE')}</p>
                        </span>
                      </div>
                    </a>
                    {process.env.NEXT_PUBLIC_DISABLE_INTERNATIONAL == 'false' && (
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault()
                          getMegamenuData(2, t('common.INTERNATIONAL'), t('auctionLite.RESIDENTIAL'))
                          dataLayerHandler('international', 'Header')
                        }}
                      >
                        <div className={css.navbar__dropdown__dropdownitem}>
                          <span>
                            <IconoGraphy iconClass={'icon-globe-map'} iconColor={'color_purple'}></IconoGraphy>
                          </span>
                          <span className={css.navbar__dropdown__dropdownitem__text}>
                            {t('common.INTERNATIONAL')}
                            <p> {t('common.INTERNATIONAL_SUBTITLE')}</p>
                          </span>
                        </div>
                      </a>
                    )}
                  </div>
                  <div className={css.dropdown_leftsecCommercial}>
                  <h3>{t('auctionLite.COMMERCIAL')}</h3>
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        getMegamenuData(0, t('common.FOR_SALE'), t('auctionLite.COMMERCIAL'))
                        dataLayerHandler('for_sale', 'Header')
                      }}
                    >
                      <div className={css.navbar__dropdown__dropdownitem} id = "forSaleCommercial">
                        <span className={css.navbar__dropdown__dropdownitem_icon}>
                          <IconoGraphy iconClass={'icon-sale'} iconColor={'color_purple'}></IconoGraphy>
                        </span>
                        <span className={css.navbar__dropdown__dropdownitem__text}>
                          {t('common.FOR_BUY_LABEL_SIDEBAR')}
                          <p> {t('common.FOR_SALE_SUBTITLE')}</p>
                        </span>
                      </div>
                    </a>
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        getMegamenuData(1, t('common.FOR_RENT'), t('auctionLite.COMMERCIAL'))
                        dataLayerHandler('for_rent', 'Header')
                      }}
                    >
                      <div className={css.navbar__dropdown__dropdownitem} id = "forRentCommercial">
                        <span className={css.navbar__dropdown__dropdownitem_icon}>
                          <IconoGraphy iconClass={'icon-rent'} iconColor={'color_purple'}></IconoGraphy>
                        </span>
                        <span className={css.navbar__dropdown__dropdownitem__text}>
                          {t('common.FOR_RENT_LABEL_SIDEBAR')}
                          <p> {t('common.FOR_RENT_SUBTITLE')}</p>
                        </span>
                      </div>
                    </a>
                  </div>
                  </div>
                  <div className={css.dropdown_rightsec}>
                    {headerCitiesNavbar && MegamenuDesktop && (
                      <MegamenuDesktop.default
                        navLinks={headerCitiesNavbar}
                        handleNavbar={handleNavbar}
                        navbarOpen={navbarOpen}
                        navTitle={navTitle}
                        metaData={metaData}
                        routerParams={routerParams}
                        pageType={pageType}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {process.env.NEXT_PUBLIC_DISABLE_AUCTION_FLOW == 'false' && (
            <a
              href={`${process.env.NEXT_PUBLIC_AUCTIONBASE_URL}${locale == 'en' ? '/en' : ''}`}
              className={`${css.navbar__link} ${textColor} ${activeMenu === 'auctions' ? css.active : ''}`}
              ref={refAuctionPopup}
              id = "auction"
              onClick={() => {
                dataLayerHandler('Auctions Clicked', pageType)
              }}
            >
              {t('common.AUCTIONS')}
              {statusTag === "live" && <Image src={liveLabel} width={32} height={20} className={css.liveLabelAnimate} alt='live'/>}
              {statusTag === "upcoming" && <div className={css.upcomingPulse}>
              <div className={css.PulseAnime}></div>
              </div>}
            </a>
          )}
          <a
            href={new URL((lang == 'en' ? '/en' : '') + MASTERPLAN_LANDING_ENDPOINT, process.env.NEXT_PUBLIC_BASE_URL)}
            className={`${css.navbar__link} ${textColor} ${activeMenu === 'auctions' ? css.active : ''}`} id = "masterplan"
          >
            {t('MasterplanBanner.MASTERPLAN')}
          </a>
          {process.env.NEXT_PUBLIC_DISABLE_MASTERPLAN_FLOW == 'false' && (
            <div
              className={`${css.navbar__link} ${textColor} ${activeMenu === 'Muhakatat' ? css.active : ''}`}
              onClick={() => {
                router.push(`${process.env.NEXT_PUBLIC_MASTERPLAN_LANDING_URL}${lang == 'en' ? '/en' : ''}`)
                dataLayerHandler('header_muhakatat', pageType)
              }}
            >
              {t('COMPOUND.MASTERPLAN')}
              
            </div>
          )}
         
          <div className={css.actionBtns}>
            <div className={`${css.navbar__link} ${textColor}`}>
              <div
                id = "support"
                onClick={(e) => {
                  e.stopPropagation()
                  commonNavAction(2)
                  dataLayerHandler('support_clicked', 'Header')
                }}
              >
                {t('common.SUPPORT')}
              </div>
              {showSupportDropdown && (
                <SupportDropdown
                  onClose={() => {
                    setshowSupportDropdown(false)
                  }}
                  pageType={pageType}
                  dataLayerHandler={dataLayerHandler}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default PrimaryNav

